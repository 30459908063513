
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  methods: {
    goToInterviewDetail() {
      this.$router.push("/interview/jobposting-detail");
    },
  },
  setup() {
    const { t } = useI18n();

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
    });

    return {};
  },
});
