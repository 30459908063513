<template>
  <div id="kt_app_content_container" class="app-container container-xxl">
    <div class="row">
      <div class="col-md-4">
        <div class="card mb-5 mb-xl-8">
          <div
            class="
              card-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <!-- Title or any other content can go here -->
            <span class="fw-bold fs-4">Aday Bilgileri</span>

            <!-- Three dots icon for dropdown -->
            <div class="dropdown">
              <button
                class="btn btn-icon btn-sm"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-three-dots fs-3"></i>
              </button>
              <div
                class="
                  dropdown-menu
                  menu
                  menu-sub
                  menu-sub-dropdown
                  menu-column
                  menu-rounded
                  menu-gray-800
                  menu-state-bg-light-primary
                  fw-bold
                  py-4
                  w-250px
                  fs-6
                "
              >
                <!--begin::Menu item-->
                <div class="menu-item px-5 my-1">
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_update_customer"
                    class="menu-link px-5"
                  >
                    Düzenle
                  </a>
                </div>
                <!--end::Menu item-->

                <!--begin::Menu item-->
                <div class="menu-item px-5">
                  <a href="#" class="menu-link text-danger px-5"> Adayı Sil </a>
                </div>
                <!--end::Menu item-->
              </div>
            </div>
          </div>

          <!--begin::Card body-->
          <div class="card-body">
            <!--begin::Summary-->
            <div class="d-flex flex-center flex-column mb-5">
              <!--begin::Avatar-->
              <div class="symbol symbol-100px symbol-circle mb-7">
                <!--begin:: Avatar -->
                <div
                  class="symbol symbol-circle symbol-50px overflow-hidden"
                >
                  <a>
                    <div class="symbol-label fs-1 bg-light-danger text-danger">
                      B
                    </div>
                  </a>
                </div>
                <!--end::Avatar-->
              </div>
              <!--end::Avatar-->

              <!--begin::Ad-->
              <a
                href="#"
                class="fs-3 text-gray-800 text-hover-primary fw-bold mb-1"
              >
                Buse Çallı
              </a>
              <!--end::Ad-->

              <!--begin::Badge-->
              <div class="badge badge-light-info d-inline">
                Software Engineer
              </div>
              <!--begin::Badge-->
              <!-- <a
                class="
                  btn btn-sm btn-secondary
                  mb-2 mb-md-0
                  me-md-2
                  mt-4
                  flex-grow-1
                "
              >
                Cv İndir
              </a> -->
            </div>
            <!--end::Summary-->

            <!--begin::Details content-->
            <div id="kt_customer_view_details" class="collapse show">
              <div class="py-2 fs-6">
                <!--begin::Details item-->
                <div class="fw-bold mt-5">E-posta</div>
                <div class="text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary"
                    >busecalli25@gmail.com</a
                  >
                </div>
                <!--begin::Details item-->
                <!--begin::Details item-->
                <div class="fw-bold mt-5">Telefon</div>
                <div class="text-gray-600">+905359807641</div>
                <!--begin::Details item-->
                <!--begin::Details item-->
                <div class="fw-bold mt-5">Adres</div>
                <div class="text-gray-600">
                  Cevatpaşa Mahallesi<br />Çalışkan Sokak No 14 Daire 7<br />Istanbul,
                  Turkiye
                </div>
                <!--begin::Details item-->
                <!--begin::Details item-->
                <div class="fw-bold mt-5">Yabancı Dil</div>
                <div class="text-gray-600">İngilizce</div>
                <!--begin::Details item-->
                <!--begin::Details item-->
                <div class="fw-bold mt-5">İş Tecrübesi</div>
                <div class="text-gray-600">2 yıl</div>
                <!--begin::Details item-->
                <!--begin::Details item-->
                <div class="fw-bold mt-5">Mezun Olduğu Bölüm</div>
                <div class="text-gray-600">Yazılım Mühendisliği</div>
                <!--begin::Details item-->
              </div>
            </div>

            <div class="separator separator-dashed my-3"></div>
            <!--begin::Details toggle-->

            <div
              class="
                d-flex
                flex-column flex-md-row
                justify-content-between
                mt-2
              "
            >
              <a
                class="
                  btn btn-sm btn-primary
                  mb-2 mb-md-0
                  me-md-2
                  flex-grow-1
                  w-100
                "
              >
                CV İndir
              </a>

              <!-- <a
                class="
                  btn btn-sm btn-danger
                  mb-2 mb-md-0
                  me-md-2
                  flex-grow-1
                  w-100
                "
              >
                Adayı Sil
              </a> -->
            </div>
            <!--end::Details toggle-->

            <!--end::Details content-->
          </div>
          <!--end::Card body-->
        </div>
      </div>
      <div class="col-md-8">
        <div class="card pt-4 mb-6 mb-xl-9">
          <!--begin::Card header-->
          <div class="card-header border-0">
            <!--begin::Card title-->
            <div class="card-title d-flex justify-content-between">
              <h2>Başvurulan İlan:</h2>
              <div class="badge badge-light-info d-inline fs-7 ms-3">
                Hybrid Çalışacak Frontend Developer
              </div>
            </div>
            <!--end::Card title-->
            <!--begin::Position Status-->
            <!-- <div class="badge badge-light-success fs-6">
              Pozisyon Uygunluğu: Uygun
            </div> -->
            <!--end::Position Status-->
          </div>
          <!--end::Card header-->

          <!--begin::Card body-->
          <div class="card-body pt-0 mb-5 mt-5">
            <!--begin::Mülakat Aşamaları-->
            <div
              class="
                d-flex
                flex-wrap
                justify-content-between
                align-items-center
                gap-3
              "
            >
              <!-- Teknik Mülakat -->
              <div class="text-center flex-fill position-relative">
                <div class="m-2">
                  <i class="bi bi-arrow-right-circle fs-2 text-primary"></i>
                </div>
                <div class="badge badge-light fs-5 mb-3">Teknik Mülakat</div>
                <div class="mt-2">
                  <span class="badge badge-light-success fs-7">Tamamlandı</span>
                </div>
                <div
                  class="
                    border-top border-success
                    position-absolute
                    top-0
                    start-0
                    w-100
                    h-100
                    rounded-3
                    border-4
                  "
                  style="border-radius: 15px"
                ></div>
              </div>

              <!-- İngilizce Mülakat -->
              <div class="text-center flex-fill position-relative">
                <div class="m-2">
                  <i class="bi bi-arrow-right-circle fs-2 text-primary"></i>
                </div>
                <div class="badge badge-light fs-5 mb-3">İngilizce Mülakat</div>
                <div class="mt-2">
                  <span class="badge badge-light-warning fs-7"
                    >Davet Gönderildi</span
                  >
                </div>
                <div
                  class="
                    border-top border-warning
                    position-absolute
                    top-0
                    start-0
                    w-100
                    h-100
                    rounded-3
                    border-4
                  "
                  style="border-radius: 15px"
                ></div>
              </div>

              <!-- Star Mülakat -->
              <div class="text-center flex-fill position-relative">
                <div class="m-2">
                  <i class="bi bi-arrow-right-circle fs-2 text-primary"></i>
                </div>
                <div class="badge badge-light fs-5 mb-3">Star Mülakat</div>
                <div class="mt-2">
                  <a class="btn btn-sm btn-primary"> Davet Gönder </a>
                </div>
                <div
                  class="
                    border-top border-secondary
                    position-absolute
                    top-0
                    start-0
                    w-100
                    h-100
                    rounded-3
                    border-4
                  "
                  style="border-radius: 15px"
                ></div>
              </div>

              <!-- Yazılım Test Mülakatı -->
              <div class="text-center flex-fill position-relative">
                <div class="m-2">
                  <i class="bi bi-arrow-right-circle fs-2 text-primary"></i>
                </div>
                <div class="badge badge-light fs-5 mb-3">
                  Yazılım Test Mülakatı
                </div>
                <div class="mt-2">
                  <span class="badge badge-light-warning fs-7"
                    >Davet Gönderildi</span
                  >
                </div>
                <div
                  class="
                    border-top border-warning
                    position-absolute
                    top-0
                    start-0
                    w-100
                    h-100
                    rounded-3
                    border-4
                  "
                  style="border-radius: 15px"
                ></div>
              </div>
            </div>
            <!--end::Mülakat Aşamaları-->
          </div>

          <!--end::Card body-->
        </div>

        <div class="card pt-4 mb-6 mb-xl-9">
          <!--begin::Card header-->
          <div class="card-header border-0">
            <!--begin::Card title-->
            <div class="card-title">
              <h2>Mülakat Bilgileri</h2>
            </div>
            <!--end::Card title-->
          </div>
          <!--end::Card header-->

          <!--begin::Card body-->
          <div class="card-body pt-0 pb-5">
            <!--begin::Table-->
            <div
              id="kt_table_customers_payment_wrapper"
              class="dt-container dt-bootstrap5 dt-empty-footer"
            >
              <div class="table-responsive">
                <table class="table align-middle table-row-dashed gy-5">
                  <thead class="border-bottom border-gray-200 fs-7 fw-bold">
                    <tr class="text-start text-muted text-uppercase gs-0">
                      <th class="min-w-100px">Mülakat Başlığı</th>
                      <th class="min-w-100px">Durum</th>
                      <th class="min-w-100px">Son İşlem Tarihi</th>
                    </tr>
                  </thead>
                  <tbody class="fs-6 fw-semibold text-gray-600">
                    <tr class="clickable-row" @click="goToInterviewDetail">
                      <td>
                        <a
                          href="#"
                          class="text-gray-600 text-hover-primary mb-1"
                          >Teknik Mülakat</a
                        >
                      </td>
                      <td>
                        <span class="badge badge-light-success"
                          >Tamamlandı</span
                        >
                      </td>
                      <td data-order="2020-12-14T20:43:00+03:00">
                        14 Aralık 2022, 18:43
                      </td>
                    </tr>
                    <tr class="clickable-row" @click="goToInterviewDetail">
                      <td>
                        <a
                          href="#"
                          class="text-gray-600 text-hover-primary mb-1"
                          >İngilizce Mülakat</a
                        >
                      </td>
                      <td>
                        <span class="badge badge-light-warning"
                          >Davet Gönderildi</span
                        >
                      </td>
                      <td data-order="2020-12-01T10:12:00+03:00">
                        01 Aralık 2022, 10:12
                      </td>
                    </tr>
                    <tr class="clickable-row" @click="goToInterviewDetail">
                      <td>
                        <a
                          href="#"
                          class="text-gray-600 text-hover-primary mb-1"
                          >Yazılım Test Mülakatı Mülakat</a
                        >
                      </td>
                      <td>
                        <span class="badge badge-light-warning"
                          >Davet Gönderildi</span
                        >
                      </td>
                      <td data-order="2020-12-01T10:12:00+03:00">
                        01 Aralık 2022, 10:10
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="row">
                <div
                  class="
                    col-sm-12 col-md-5
                    d-flex
                    align-items-center
                    justify-content-center justify-content-md-start
                    dt-toolbar
                  "
                ></div>
                <div
                  class="
                    col-sm-12 col-md-7
                    d-flex
                    align-items-center
                    justify-content-center justify-content-md-end
                  "
                >
                  <div class="dt-paging paging_simple_numbers">
                    <nav aria-label="pagination">
                      <ul class="pagination">
                        <li class="dt-paging-button page-item disabled">
                          <button
                            class="page-link previous"
                            role="link"
                            type="button"
                            aria-controls="kt_table_customers_payment"
                            aria-disabled="true"
                            aria-label="Previous"
                            data-dt-idx="previous"
                            tabindex="-1"
                          >
                            <i class="previous"></i>
                          </button>
                        </li>
                        <li class="dt-paging-button page-item active">
                          <button
                            class="page-link"
                            role="link"
                            type="button"
                            aria-controls="kt_table_customers_payment"
                            aria-current="page"
                            data-dt-idx="0"
                          >
                            1
                          </button>
                        </li>
                        <li class="dt-paging-button page-item">
                          <button
                            class="page-link"
                            role="link"
                            type="button"
                            aria-controls="kt_table_customers_payment"
                            data-dt-idx="1"
                          >
                            2
                          </button>
                        </li>
                        <li class="dt-paging-button page-item">
                          <button
                            class="page-link next"
                            role="link"
                            type="button"
                            aria-controls="kt_table_customers_payment"
                            aria-label="Next"
                            data-dt-idx="next"
                          >
                            <i class="next"></i>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <!--end::Table-->
          </div>
          <!--end::Card body-->
        </div>
      </div>
    </div>
    <!--begin::Modal - Update Candidate-->
    <div
      class="modal fade"
      id="kt_modal_update_customer"
      tabindex="-1"
      aria-hidden="true"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Form-->
          <form class="form" action="#" id="kt_modal_update_customer_form">
            <!--begin::Modal header-->
            <div class="modal-header" id="kt_modal_update_customer_header">
              <!--begin::Modal title-->
              <h2 class="fw-bold">Aday Düzenle</h2>
              <!--end::Modal title-->

              <!--begin::Close-->
              <div
                id="kt_modal_update_customer_close"
                class="btn btn-icon btn-sm btn-active-icon-primary"
              >
                <i class="ki-duotone ki-cross fs-1"
                  ><span class="path1"></span><span class="path2"></span
                ></i>
              </div>
              <!--end::Close-->
            </div>
            <!--end::Modal header-->

            <!--begin::Modal body-->
            <div class="modal-body py-10 px-lg-17">
              <!--begin::Scroll-->
              <div
                class="d-flex flex-column scroll-y me-n7 pe-7"
                id="kt_modal_update_customer_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_update_customer_header"
                data-kt-scroll-wrappers="#kt_modal_update_customer_scroll"
                data-kt-scroll-offset="300px"
                style="max-height: 650px"
              >
                <!--begin::Notice-->

                <!--begin::Notice-->
                <div
                  class="
                    notice
                    d-flex
                    bg-light-primary
                    rounded
                    border-primary border border-dashed
                    mb-9
                    p-6
                  "
                >
                  <!--begin::Icon-->
                  <i class="ki-duotone ki-information fs-2tx text-primary me-4"
                    ><span class="path1"></span><span class="path2"></span
                    ><span class="path3"></span
                  ></i>
                  <!--end::Icon-->

                  <!--begin::Wrapper-->
                  <div class="d-flex flex-stack flex-grow-1">
                    <!--begin::Content-->
                    <div class="fw-semibold">
                      <div class="fs-6 text-gray-700">
                        Aday düzenleme işlemlerinde veri gizliliği kapsamında ve
                        KVKK dahilinde işlem yapılmaktadır.
                      </div>
                    </div>
                    <!--end::Content-->
                  </div>
                  <!--end::Wrapper-->
                </div>
                <!--end::Notice-->
                <!--end::Notice-->

                <!--begin::User toggle-->
                <div
                  class="fw-bold fs-3 rotate collapsible mb-7"
                  data-bs-toggle="collapse"
                  href="#kt_modal_update_customer_user_info"
                  role="button"
                  aria-expanded="false"
                  aria-controls="kt_modal_update_customer_user_info"
                >
                  Aday Bilgileri
                  <span class="ms-2 rotate-180">
                    <i class="ki-duotone ki-down fs-3"></i>
                  </span>
                </div>
                <!--end::User toggle-->

                <!--begin::User form-->
                <div
                  id="kt_modal_update_customer_user_info"
                  class="collapse show"
                >
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2">Ad</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="name"
                      value="Buse Çallı"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->

                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2">
                      <span>E-posta</span>

                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        aria-label="E-posta address must be active"
                        data-bs-original-title="E-posta address must be active"
                        data-kt-initialized="1"
                      >
                        <i class="ki-duotone ki-information fs-7"
                          ><span class="path1"></span><span class="path2"></span
                          ><span class="path3"></span
                        ></i>
                      </span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      type="email"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="email"
                      value="busecalli25@gmail.com"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2">Telefon</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="telephone"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2">Adres</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="address"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2">Yabancı Dil</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="language"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2">İş Tecrübesi</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="experience"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-7">
                    <!--begin::Label-->
                    <label class="fs-6 fw-semibold mb-2"
                      >Mezun Olduğu Bölüm</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="university"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                </div>
                <!--end::User form-->
              </div>
              <!--end::Scroll-->
            </div>
            <!--end::Modal body-->

            <!--begin::Modal footer-->
            <div class="modal-footer flex-center">
              <!--begin::Button-->
              <button
                type="reset"
                id="kt_modal_update_customer_cancel"
                class="btn btn-light me-3"
              >
                Vazgeç
              </button>
              <!--end::Button-->

              <!--begin::Button-->
              <button
                type="submit"
                id="kt_modal_update_customer_submit"
                class="btn btn-primary"
              >
                <span class="indicator-label"> Güncelle </span>
                <span class="indicator-progress">
                  Lütfen bekleyiniz...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
              <!--end::Button-->
            </div>
            <!--end::Modal footer-->
          </form>
          <!--end::Form-->
        </div>
      </div>
    </div>
    <!-- end::Modal-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Pollster",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  methods: {
    goToInterviewDetail() {
      this.$router.push("/interview/jobposting-detail");
    },
  },
  setup() {
    const { t } = useI18n();

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
    });

    return {};
  },
});
</script>

<style>
.clickable-row {
  cursor: pointer; /* Tıklanabilirlik özelliği */
  transition: background-color 0.3s ease; /* Arka plan renginin yumuşak geçişi */
}

.clickable-row:hover {
  background-color: #f0f0f0; /* Hover durumunda gri arka plan */
}

/* İstenirse satır metni de renklendirilebilir */
.clickable-row a {
  color: #333;
  text-decoration: none;
}

.clickable-row a:hover {
  color: #007bff; /* Linkin üzerine gelindiğinde renk değişikliği */
}
</style>
